<template>
  <div class="accordion" id="acceleration-learn">
    <div class="accordion__item container-shadow">
      <div class="accordion__header" id="headingOne">
        <h2 class="collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false"
            aria-controls="collapseOne">
          Отбор в тренеры программы «Экспортный Мини-МВА»
        </h2>
      </div>
      <div id="collapseOne" class="collapse accordion__body show" aria-labelledby="headingOne" data-parent="#acceleration-learn">
        <div class="section__acceleration-description-text mb-2">
          <p>Школа экспорта РЭЦ начинает <b class="blue-text">конкурсный отбор кандидатов в тренеры</b> для реализации программы <b class="blue-text">«Экспортный Мини-МВА»</b>.</p>

          <p>К участию в конкурсном отборе приглашаются специалисты с практическим опытом экспортной деятельности, имеющие навыки по продвижению компаний на внешние рынки.</p>

        </div>
        <div class="section__acceleration-description-text mb-2">
          <h3 class="mb-2">Для участия в конкурсном отборе кандидатам необходимо:</h3>
          <ul class="list list_dotted">
            <li>Соответствовать требованиям (см. ниже).</li>
            <li>Заполнить анкету и формы самостоятельной оценки, предоставить подтверждающие документы в Личном кабинете.</li>
            <li>Успешно пройти входной профессиональный тест.</li>
          </ul>
        </div>
        <div class="section__acceleration-description-text mb-2">
          <blockquote>
            <p>Кандидаты, прошедшие начальный этап отбора, допускаются к <b>профильному обучению и аттестации в период с мая по август текущего года</b>.</p>
          </blockquote>
          <div class="container-grid section__coaches-page-docs mb-3">
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg" />

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Предварительный график обучения по программе</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a target="_blank" download="Предварительный график обучения по программе.pdf" href="https://exporteduru.servicecdn.ru/media/default/0002/63/0cbe0571186b70e744faca74fcddc0aabf6599c4.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg" />

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Инструкция для кандидатов в тренеры-наставники программы «Экспортный Мини-МВА»</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a target="_blank" download="Инструкция для кандидатов в тренеры-наставники программы «Экспортный Мини-МВА».pdf" href="https://exporteduru.servicecdn.ru/media/default/0002/64/8332ea10c1bee19f269fe3641558ad530644db54.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
          </div>
          <p style="padding: 0; margin-bottom: 0;">Кандидаты, которые успешно прошли обучение и установленные программой аттестационные процедуры, становятся тренерами программы.</p>
        </div>
        <div class="mb-5 mt-5">
          <span class="text-danger">ВНИМАНИЕ! ОТБОР КАНДИДАТОВ В ТРЕНЕРЫ В 2022 ГОДУ ЗАВЕРШЕН 18.04.2022</span>
        </div>
        <div class="section__acceleration-description-text mb-2">
          <p>Узнать подробнее о программе «Экспортный Мини-МВА», целевой аудитории программы, ее задачах и возможностях можно в специальном разделе для клиентов программы.</p>
        </div>
        <div class="mb-4">
          <a href="/acceleration#msp" style="margin: 0;" class="link-target"><span>Подробнее о программе</span></a>
        </div>
      </div>
    </div>
    <div class="accordion__item container-shadow">
      <div class="accordion__header" id="headingTwo">
        <h2 class="collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
            aria-controls="collapseOne">
          Основные задачи тренера программы «Экспортный Мини-МВА»
        </h2>
      </div>
      <div id="collapseTwo" class="collapse accordion__body" aria-labelledby="headingOne"
           data-parent="#acceleration-learn">
        <div class="section__acceleration-description-text">
          <div class="mb-4">
            <h3 class="mb-2">Тренер программы «Экспортный Мини-МВА»</h3>
            <ul class="list list_dotted">
              <li>проводит обучение представителей компаний МСП по модулям программы;</li>
              <li>проводит консультации по вопросам, рассматриваемым в процессе обучения;</li>
              <li>осуществляет информационную и консультационную поддержку при разработке отдельных разделов бизнес-плана всех компаний, участвующих в обучении;</li>
              <li>формирует экспертную оценку заполненных бизнес-планов всех компаний, участвующих в обучении;</li>
              <li>обеспечивает взаимодействие с другими тренерами программы и передачу необходимой информации о прогрессе компаний-участников.</li>
            </ul>
          </div>
          <div class="mb-2">
            <h3 class="mb-2">Возможности для тренеров программы «Экспортный Мини-МВА»</h3>
            <ul class="list list_dotted">
              <li>в статусе аккредитованного федерального тренера Школы экспорта РЭЦ вас будут <span class="red-text">привлекать на платной основе для оказания образовательных услуг</span> и консультационной поддержки данной программы, реализуемой на базе региональных центров поддержки экспорта;</li>
              <li>вы сможете <span class="red-text">повысить свой профессиональный статус</span>, выйти на федеральный уровень взаимодействия с бизнесом, руководством региональных органов исполнительной власти и инфраструктуры поддержки экспорта;</li>
              <li>у вас появится возможность <span class="red-text">принимать участие в работе экспертных профессиональных площадок</span>, в том числе организуемых Школой экспорта РЭЦ, для обмена опытом и знаниями;</li>
              <li>в случае заинтересованности в профессиональном росте и развитии внутри программ Школы экспорта РЭЦ, вы получите <span class="red-text">специальные условия (скидки)</span> на обучение по программам.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion__item container-shadow">
      <div class="accordion__header" id="headingThree">
        <h2 class="collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
            aria-controls="collapseOne">
          Требования к кандидатам в тренеры
        </h2>
      </div>
      <div id="collapseThree" class="collapse accordion__body" aria-labelledby="headingOne"
           data-parent="#acceleration-learn">
        <div class="table-wrapper table-wrapper_scrolled mb-7 section__acceleration-description-text">
          <table class="table_bordered">
            <thead>
            <tr>
              <th>ПРОФИЛЬ</th>
              <th>ТРЕНЕР программы &laquo;Экспортный Мини-МВА&raquo;</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <p>Квалификационные требования</p>
              </td>
              <td>
                <ul class="list list_dotted">
                  <li>законченное высшее образование (специалитет, магистратура);</li>
                  <li>дополнительное образование в области внешнеэкономической деятельности (желательно);</li>
                  <li>дополнительное образование в области обучения взрослых: тренинги для тренеров, программы обучения коучингу, менторингу и т.п. (желательно);</li>
                  <li>гражданство &ndash; Российская Федерация.</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>Опыт</p>
              </td>
              <td>
                <ul class="list list_dotted">
                  <li>в экспортной деятельности &ndash; от 1 года;</li>
                  <li>в сфере, соответствующей теме модуля программы &ndash; от 5 лет;</li>
                  <li>по обучению взрослых (тренерская, преподавательская деятельность) &ndash; от 3 лет (желательно)</li>
                  <li>по сопровождению процесса выхода компании на международные рынки (желательно);</li>
                  <li>по администрированию проектов (информационное и организационное) (желательно).</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>Профессиональные знания и навыки&nbsp;</p>
              </td>
              <td>
                <ul class="list list_dotted">
                  <li>умение работать с группой людей в интерактивном обучающем формате;</li>
                  <li>умение оценить аудиторию слушателей и откорректировать контент обучения, не нарушая утвержденную структуру и логику программы и требования Школы экспорта РЭЦ;</li>
                  <li>знание основ ВЭД;</li>
                  <li>знание системы поддержки экспорта;</li>
                  <li>знание мер государственной поддержки экспорта, знание продуктов и услуг РЭЦ и ЦПЭ (желательно);</li>
                  <li>знание основ проектного менеджмента;</li>
                  <li>продвинутый пользователь офисных программ, образовательных онлайн-платформ, социальных сетей и других современных способов электронных коммуникаций.</li>
                </ul>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Basic"
}
</script>

<style scoped>

</style>